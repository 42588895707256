import { ref, computed, watch, reactive, toRefs, readonly, onMounted, isRef, toRef } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { router, usePage } from '@inertiajs/vue3';
import debounce from 'lodash.debounce';
import { Question, Meeting, Poll, Response, MultipleChoice, Rating, WordCloud, OpenText } from '@/models';
import { PollTypes, can } from './shared';
import Cookies from 'js-cookie';
import { isDarkTheme } from './Components/ThemePicker.vue';

export function useFilterFetch() {
    // get current state from url
    // update url when mutating state
    // do not return items. It will be returned by page redirection

    const urlSearchParams = useUrlSearchParams('history');

    const order = computed({
        get() {
            return {
                by: urlSearchParams.orderBy,
                direction: urlSearchParams.orderDirection,
            };
        },
        set({ by, direction }) {
            router.get(location.pathname, { ...urlSearchParams, orderBy: by, orderDirection: direction });
        },
    });

    const q = computed({
        get() {
            return urlSearchParams.q;
        },
        set: debounce(val => {
            router.get(
                location.pathname,
                { ...urlSearchParams, page: 1, q: val || undefined },
                { preserveState: true },
            );
        }, 500),
    });

    const page = computed({
        get() {
            return urlSearchParams.page ? Number(urlSearchParams.page) : 1;
        },
        set(val) {
            router.get(location.pathname, { ...urlSearchParams, page: val });
        },
    });

    return {
        order,
        q,
        page,
    };
}

export function useModal(initOpen = false) {
    const isOpen = ref(initOpen);

    return reactive({
        isOpen,
        open: () => (isOpen.value = true),
        close: () => (isOpen.value = false),
    });
}

export function useModerator(props) {
    const meeting = computed(() => Meeting.format(props.meeting));
    const polls = computed(() => props.polls.map(Poll.format));

    /** @type {import('vue').ComputedRef<Poll|null>} */
    const activePoll = computed(() =>
        props.activePollId ? polls.value.find(poll => poll.id === props.activePollId) : null,
    );

    const responses = ref(
        props.responses && activePoll.value
            ? props.responses.map(response => Response.format(response, activePoll.value.type))
            : null,
    );
    watch(
        () => props.responses,
        newResponses => {
            if (newResponses && activePoll.value) {
                responses.value = newResponses.map(response => Response.format(response, activePoll.value.type));
                return;
            }

            responses.value = null;
        },
    );

    Echo.channel(`moderator.${meeting.value.encryptedMeetingUuid}`)
        .listen('QuestionAdded', event => handleQuestionAdded(Question.format(event.question)))
        .listen('QuestionDeleted', event => handleQuestionDeleted(Question.format(event.question)))
        .listen('QuestionUpdated', event => handleQuestionUpdated(Question.format(event.question)))
        .listen('QuestionUpvoted', ({ question, removed }) => handleQuestionUpvoted(Question.format(question)))
        .listen('QuestionAnswered', event => handleQuestionAnswered(Question.format(event.question)))

        .listen('OpenTextAdded', event => handleOpenTextAdded(OpenText.format(event.openText)))
        .listen('OpenTextDeleted', event => handleOpenTextDeleted(OpenText.format(event.openText)))

        .listen('RatingAdded', event => handleRatingAdded(Rating.format(event.rating)))
        .listen('MultipleChoiceAdded', event => handleMultipleChoiceAdded(MultipleChoice.format(event.multipleChoice)))
        .listen('WordCloudAdded', event => handleWordCloudAdded(WordCloud.format(event.wordCloud)));

    /**
     *
     * @param {Question} question
     */
    function handleQuestionAdded(question) {
        if (activePoll.value?.type != PollTypes.QA) return;
        addResponse(question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionDeleted(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionUpdated(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question, question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionUpvoted(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question, question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionAnswered(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question, question);
    }

    /**
     *
     * @param {OpenText} openText
     */
    function handleOpenTextAdded(openText) {
        if (activePoll.value?.type != PollTypes.OPEN_TEXT) return;
        addOrReplaceResponse(openText, openText);
    }

    /**
     *
     * @param {OpenText} openText
     */
    function handleOpenTextDeleted(openText) {
        if (activePoll.value?.type !== PollTypes.OPEN_TEXT) return;
        replaceResponse(openText);
    }

    /**
     *
     * @param {Rating} rating
     */
    function handleRatingAdded(rating) {
        if (activePoll.value?.type != PollTypes.RATING) return;
        addResponse(rating);
    }

    /**
     *
     * @param {MultipleChoice} multipleChoice
     */
    function handleMultipleChoiceAdded(multipleChoice) {
        if (activePoll.value?.type != PollTypes.MULTIPLE_CHOICE) return;
        addResponse(multipleChoice);
    }

    /**
     *
     * @param {WordCloud} wordCloud
     */
    function handleWordCloudAdded(wordCloud) {
        if (activePoll.value?.type != PollTypes.WORD_CLOUD) return;

        /** @type {Array<{wordCount: number, word: string}>} responses.value */

        const index = responses.value.findIndex(
            response => response.word.toLowerCase().trim() === wordCloud.word.toLowerCase().trim(),
        );

        // if it does not exists yet, create new entry
        if (index < 0) {
            addResponse(wordCloud);
            return;
        }

        // otherwise replace with new value which contains the new count
        responses.value.splice(index, 1, wordCloud);
    }

    /**
     *
     * @param {PollResponse} response
     */
    function addResponse(response) {
        responses.value.push(response);
    }

    /**
     *
     * @param {PollResponse} response
     * @param {PollResponse} newResponse
     */
    function addOrReplaceResponse(response, newResponse) {
        const index = responses.value.findIndex(q => q.id === response.id);
        if (index < 0) {
            addResponse(newResponse);
            return;
        }
        replaceResponse(response, newResponse);
    }

    /**
     *
     * @param {PollResponse} response
     * @param {PollResponse|undefined} [newResponse]
     */
    function replaceResponse(response, newResponse = undefined) {
        const index = responses.value.findIndex(q => q.id === response.id);
        if (index < 0) return;
        if (newResponse) {
            responses.value.splice(index, 1, newResponse);
        } else {
            responses.value.splice(index, 1);
        }
    }

    return { meeting, polls, activePoll, responses };
}

export function useMeeting(props, { onPollStarted = () => null } = {}) {
    const meeting = computed(() => Meeting.format(props.meeting));

    const activePoll = ref(props.activePoll ? Poll.format(props.activePoll) : null);

    watch(
        () => props.activePoll,
        newActivePoll => (activePoll.value = newActivePoll ? Poll.format(newActivePoll) : null),
    );

    const responses = ref(
        props.responses && activePoll.value
            ? props.responses.map(response => Response.format(response, activePoll.value.type))
            : null,
    );

    watch(
        () => props.responses,
        newResponses => {
            if (newResponses && activePoll.value) {
                responses.value = newResponses.map(response => Response.format(response, activePoll.value.type));
                return;
            }

            responses.value = null;
        },
    );

    Echo.channel(`meeting.${meeting.value.uuid}`)
        .listen('QuestionAdded', event => handleQuestionAdded(Question.format(event.question)))
        .listen('QuestionDeleted', event => handleQuestionDeleted(Question.format(event.question)))
        .listen('QuestionUpdated', event => handleQuestionUpdated(Question.format(event.question)))
        .listen('QuestionUpvoted', ({ question, removed }) => handleQuestionUpvoted(Question.format(question)))
        .listen('QuestionAnswered', event => handleQuestionAnswered(Question.format(event.question)))

        .listen('OpenTextAdded', event => handleOpenTextAdded(OpenText.format(event.openText)))
        .listen('OpenTextDeleted', event => handleOpenTextDeleted(OpenText.format(event.openText)))

        .listen('QuestionApproved', ({ question, approval }) =>
            handleQuestionApproved(Question.format(question), approval),
        )
        .listen('ResponseHighlighted', ({ poll, response }) => handleResponseHighlighted(poll, response))
        .listen('ResponseUnhighlighted', ({ poll }) => handleResponseUnhighlighted(poll))

        .listen('RatingAdded', event => handleRatingAdded(Rating.format(event.rating)))
        .listen('MultipleChoiceAdded', event => handleMultipleChoiceAdded(MultipleChoice.format(event.multipleChoice)))
        .listen('WordCloudAdded', event => handleWordCloudAdded(WordCloud.format(event.wordCloud)))
        .listen('WordCloudDeleted', event => handleWordCloudDeleted(WordCloud.format(event.wordCloud)))

        .listen('PollStarted', ({ poll }) => handlePollStarted(Poll.format(poll)))
        .listen('PollStopped', () => handlePollStopped())
        .listen('ShowPollResults', event => handleShowPollResults(Poll.format(event.poll)))
        .listen('HidePollResults', event => handleHidePollResults(Poll.format(event.poll)))

        .listen('PollLocked', event => handlePollLocked(Poll.format(event.poll)))
        .listen('PollUnlocked', event => handlePollUnlocked(Poll.format(event.poll)));

    /**
     *
     * @param {Question} question
     */
    function handleQuestionAdded(question) {
        if (activePoll.value?.type != PollTypes.QA) return;
        addOrReplaceResponse(question, question);
    }

    /**
     *
     * @param {OpenText} openText
     */
    function handleOpenTextAdded(openText) {
        if (activePoll.value?.type != PollTypes.OPEN_TEXT) return;
        addOrReplaceResponse(openText, openText);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionDeleted(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionUpdated(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question, question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionUpvoted(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question, question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionAnswered(question) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        replaceResponse(question);
    }

    /**
     *
     * @param {Question} question
     */
    function handleQuestionApproved(question, approval) {
        if (activePoll.value?.type !== PollTypes.QA) return;
        if (approval) {
            addOrReplaceResponse(question, question);
        } else {
            replaceResponse(question);
        }
    }

    /**
     *
     * @param {OpenText} openText
     */
    function handleOpenTextDeleted(openText) {
        if (activePoll.value?.type !== PollTypes.OPEN_TEXT) return;
        replaceResponse(openText);
    }

    function handleResponseHighlighted(poll, response) {
        if (activePoll.value.id !== poll.id) return;
        activePoll.value.highlightedResponseId = response.id;
    }

    function handleResponseUnhighlighted(poll) {
        if (activePoll.value.id !== poll.id) return;
        activePoll.value.highlightedResponseId = null;
    }

    /**
     *
     * @param {Rating} rating
     */
    function handleRatingAdded(rating) {
        if (activePoll.value?.type != PollTypes.RATING) return;
        addResponse(rating);
    }

    /**
     *
     * @param {MultipleChoice} multipleChoice
     */
    function handleMultipleChoiceAdded(multipleChoice) {
        if (activePoll.value?.type != PollTypes.MULTIPLE_CHOICE) return;
        addResponse(multipleChoice);
    }

    /**
     *
     * @param {MultipleChoice} wordCloud
     */
    function handleWordCloudAdded(wordCloud) {
        if (activePoll.value?.type != PollTypes.WORD_CLOUD) return;

        /** @type {Array<{wordCount: number, word: string}>} responses.value */

        const index = responses.value.findIndex(
            response => response.word.toLowerCase().trim() === wordCloud.word.toLowerCase().trim(),
        );

        // if it does not exists yet, create new entry
        if (index < 0) {
            addResponse(wordCloud);
            return;
        }

        // otherwise replace with new value which contains the new count
        responses.value.splice(index, 1, wordCloud);
    }

    /**
     *
     * @param {MultipleChoice} wordCloud
     */
    function handleWordCloudDeleted(wordCloud) {
        if (activePoll.value?.type != PollTypes.WORD_CLOUD) return;
        const index = responses.value.findIndex(w => w.word === wordCloud.word);
        if (index < 0) return;
        responses.value.splice(index, 1);
    }

    /**
     *
     * @param
     */
    function handlePollStarted(poll) {
        activePoll.value = poll;
        responses.value = [];

        onPollStarted(poll);
    }

    function handlePollStopped() {
        activePoll.value = null;
        responses.value = null;
    }

    /**
     *
     * @param
     */
    function handleShowPollResults(poll) {
        activePoll.value = poll;
    }

    /**
     *
     * @param
     */
    function handlePollLocked(poll) {
        activePoll.value = poll;
    }

    /**
     *
     * @param
     */
    function handlePollUnlocked(poll) {
        activePoll.value = poll;
    }

    /**
     *
     * @param
     */
    function handleHidePollResults(poll) {
        activePoll.value = poll;
    }

    /**
     *
     * @param {PollResponse} response
     */
    function addResponse(response) {
        responses.value.push(response);
    }

    /**
     *
     * @param {PollResponse} response
     * @param {PollResponse|undefined} [newResponse]
     */
    function replaceResponse(response, newResponse = undefined) {
        const index = responses.value.findIndex(q => q.id === response.id);
        if (index < 0) return;
        if (newResponse) {
            responses.value.splice(index, 1, newResponse);
        } else {
            responses.value.splice(index, 1);
        }
    }

    /**
     *
     * @param {PollResponse} response
     * @param {PollResponse} newResponse
     */
    function addOrReplaceResponse(response, newResponse) {
        const index = responses.value.findIndex(q => q.id === response.id);
        if (index < 0) {
            addResponse(newResponse);
            return;
        }
        replaceResponse(response, newResponse);
    }

    return { meeting, activePoll, responses };
}

export function usePermissions() {
    const page = usePage();
    const user = computed(() => page.props.auth?.user);

    return {
        user,
        hasRole(role) {
            if (user.value.roles.find(userRole => userRole.name == role)) {
                return true;
            }
            return false;
        },
        can(ability) {
            if (!user.value || !user.value.allPermissions) return false;
            return can(ability, user.value);
        },
    };
}

const needHelpPopupConditions = reactive({
    createMeetingVisitedAt: null,
    noActionTaken: true,
    navigatedAwayAt: null,
});

let removeNeedHelpNavigationListener = null;

/**
 *
 * @param {{ createdAt: Date, wantsCall: boolean }} user
 * @param { undefined | function():void } onNeedHelp
 */
export function useNeedHelpPopup(user, onNeedHelp) {
    const COOKIE_KEY = 'needsHelpPopupDisplayedAt';
    const NUM_OF_DAYS = 2;

    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - NUM_OF_DAYS);

    const wasRecentlyCreated = user.createdAt >= daysAgo;
    const displayedAt = ref(Cookies.get(COOKIE_KEY));

    function setCondition(name, value) {
        needHelpPopupConditions[name] = value;
    }

    function setDisplayedAt(timestamp) {
        Cookies.set(COOKIE_KEY, timestamp, { expires: NUM_OF_DAYS });
        displayedAt.value = timestamp;
    }

    if (!removeNeedHelpNavigationListener && !displayedAt.value) {
        removeNeedHelpNavigationListener = router.on('navigate', event => {
            if (event.detail.page.component === 'Admin/Meeting/Create') {
                Object.assign(needHelpPopupConditions, {
                    navigatedAwayAt: null,
                    noActionTaken: true,
                    createMeetingVisitedAt: Date.now(),
                });
            } else {
                if (needHelpPopupConditions.createMeetingVisitedAt) {
                    needHelpPopupConditions.navigatedAwayAt = Date.now();
                }
            }
        });
    }

    if (!user.wantsCall && wasRecentlyCreated && !displayedAt.value && onNeedHelp) {
        const unwatch = watch(needHelpPopupConditions, conditions => {
            if (Object.values(conditions).every(condition => !!condition)) {
                unwatch();
                setTimeout(() => {
                    onNeedHelp();
                    removeNeedHelpNavigationListener();
                    removeNeedHelpNavigationListener = null;
                    // TODO: cross-device solution -> fetch('/client/data', 'patch', { needsHelpPopupDisplayedAt })
                    // Alternative solution, BE can on any further request read the cookie and persist into db, and set sookie
                    setDisplayedAt(Date.now());
                }, 5000);
            }
        });
    }

    return {
        conditions: readonly(needHelpPopupConditions),
        setCondition,
    };
}

/**
 *
 * @param {import('vue').Ref<string> | string} t theme
 */
export function useTvViewBackground(t) {
    const theme = toRef(t);

    const isDark = computed(() => isDarkTheme(theme.value));

    const themeType = computed(() => {
        if (/\..+$/.test(theme.value)) return 'image';
        if (/(^#)|(^rgb)|(^hsl)/.test(theme.value)) return 'color';
        return 'css';
    });

    const themeStyles = computed(() => {
        if (themeType.value === 'css') return undefined;
        if (themeType.value === 'image') {
            return {
                backgroundImage: `url("${theme.value}")`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
                backgroundAttachment: `fixed`,
            };
        }
        // color
        return { background: theme.value };
    });

    const themeClasses = computed(() => {
        return themeType.value === 'css' ? theme.value : undefined;
    });

    return {
        theme,
        isDark,
        themeType,
        themeStyles,
        themeClasses,
    };
}

/**
 *
 * @param {"meeting" | "poll"} type
 */
export function useAiGenerator(type) {
    const AI_POLL_GENERATION_STATUSES = {
        READY: 'READY',
        IN_PROGRESS: 'IN_PROGRESS',
    };

    const status = ref(AI_POLL_GENERATION_STATUSES.READY);
    const validationErrors = ref(null);
    const progress = ref(0);

    let abortController = null;
    let progressTimeouts = [];

    /**
     *
     * @param {string} prompt
     * @returns {Promise<any>}
     */
    async function generate(prompt) {
        status.value = AI_POLL_GENERATION_STATUSES.IN_PROGRESS;

        validationErrors.value = null;

        cancelPreviousRequest();

        // one request takes at most 20 seconds
        progressTimeouts.push(setTimeout(() => (progress.value = 5), 1000));
        progressTimeouts.push(setTimeout(() => (progress.value = 10), 3000));
        progressTimeouts.push(setTimeout(() => (progress.value = 20), 5000));
        progressTimeouts.push(setTimeout(() => (progress.value = 30), 7000));
        progressTimeouts.push(setTimeout(() => (progress.value = 40), 8000));
        progressTimeouts.push(setTimeout(() => (progress.value = 50), 10000));
        progressTimeouts.push(setTimeout(() => (progress.value = 60), 12000));
        progressTimeouts.push(setTimeout(() => (progress.value = 70), 13000));
        progressTimeouts.push(setTimeout(() => (progress.value = 80), 15000));
        progressTimeouts.push(setTimeout(() => (progress.value = 90), 18000));
        progressTimeouts.push(setTimeout(() => (progress.value = 95), 20000));

        let response;

        try {
            response = await fetch(
                type === 'meeting' ? route('admin.meetings.generate') : route('admin.polls.generate'),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-Token': document.querySelector('input[name="_token"]').value,
                    },
                    credentials: 'same-origin',
                    signal: abortController.signal,
                    body: JSON.stringify({
                        prompt,
                    }),
                },
            );
        } catch (e) {
            // Network error or anything what prevent the request to hit server
            // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#differences_from_jquery.ajax
            throw e;
        } finally {
            resetProgress();
            progress.value = 100;
            status.value = AI_POLL_GENERATION_STATUSES.READY;
        }

        if (!response.ok) {
            if (response.status == 422) {
                const { errors } = await response.json();
                validationErrors.value = errors;
                return;
            }

            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    function resetProgress() {
        progress.value = 0;
        progressTimeouts.forEach(timeoutId => clearTimeout(timeoutId));
        progressTimeouts = [];
    }

    function cancelPreviousRequest() {
        resetProgress();

        // TODO: here we should also notify BE to notify AI to cancle prompt to save tokens
        abortController?.abort('New prompt request');
        abortController = new AbortController();
    }

    return {
        AI_POLL_GENERATION_STATUSES,
        status,
        validationErrors,
        generate,
        cancel: cancelPreviousRequest,
        progress,
    };
}

export function useInfiniteLoading() {
    const isLoading = ref(false);

    async function load(route) {
        isLoading.value = true;
        let response;

        try {
            response = await fetch(route, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': document.querySelector('input[name="_token"]').value,
                },
                credentials: 'same-origin',
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            return data;
        } catch (e) {
            console.error('couldnt load responses');
        } finally {
            isLoading.value = false;
        }
    }

    return { load, isLoading };
}
